import { mobileCheck } from '@utils/common-methods/common'

const desktopUiFixes = {
  mounted() {
    !mobileCheck() && this.fixStyle()
  },
  destroyed() {
    !mobileCheck() && this.resetStyle()
  },
  methods: {
    fixStyle() {
      const desktopScrollContainer = document.getElementById('desktop-scroll-container')
      desktopScrollContainer.classList.add('desktop-view')

      const heightRef = document.getElementById('page-height-reference')
      const appIndex = document.querySelector('.app-index')
      if (heightRef && appIndex) appIndex.style = `height: ${heightRef.clientHeight}px;`
    },
    resetStyle() {
      const desktopScrollContainer = document.getElementById('desktop-scroll-container')
      desktopScrollContainer.classList.remove('desktop-view')
    },
  }
}
export default desktopUiFixes
