<template>
  <div class="public-portfolio-wrap">
    <div class="page-header is-flex is-align-items-center">
      <img src="@assets/images/dashboard/investor.png" alt="avatar" class="avatar">
      <div class="has-padding">
        <div class="header-title has-text-weight-semibold">{{memberName}} owns a part of {{propertyNickname}}!</div>
        <div class="handle">{{addyHandle}}</div>
      </div>
    </div>
    <div class="miniprop has-text-centered">
      <div class="property-nickName has-text-weight-semibold">{{propertyNickname}}</div>
      <div :class="`property-address ${isCertificateSharing ? 'noMiniPropImage' : ''}`">{{propertyName}}</div>
      <div class="miniprop-wrap" v-if="!isCertificateSharing" :style="`background: url(${miniProp}) center/contain no-repeat;`"></div>
    </div>
    <div v-if="!isCertificateSharing" class="property-images">
      <div class="swiper-box" v-if="propertyImages.length > 1">
        <div class="property-images-swiper-container">
          <div class="swiper-wrapper">
            <div
              v-for="(image, index) in propertyImages"
              :key="index"
              class="swiper-slide">
                <div :style="`background: url(${image}) center/cover no-repeat;`" class="image-block"></div>
              </div>
          </div>
        </div>
        <div class="arrows is-flex is-justify-content-center">
          <img src="@assets/icons/arrow-left.svg" alt="arrow-left" class="arrow-left is-clickable" @click="slidePrev">
          <img src="@assets/icons/arrow-right.svg" alt="arrow-right" class="arrow-right is-clickable" @click="slideNext">
        </div>
      </div>
      <div class="has-text-centered has-primary-only" v-else>
        <div class="primary-img" :style="`background: url(${propertyImages[0]}) center/cover no-repeat;`"></div>
      </div>
    </div>
    <div v-if="!isCertificateSharing" class="metrics">
      <div class="is-flex is-justify-content-space-between has-margin">
        <div :class="`metric-block has-background-white has-text-centered ${saleStatus === 'settled' ? '' : 'full-width'}`">
          <div class="metric-title has-text-weight-semibold">Invested</div>
          <div class="has-padding">
            <span class="sign sup has-text-weight-semibold has-text-blue">$</span>
            <span class="value has-text-blue">{{totalInvestedAmount | formatter}}</span>
          </div>
        </div>
        <div class="metric-block has-background-white has-text-centered" v-if="saleStatus === 'settled'">
          <div class="metric-title has-text-weight-semibold">Ownership</div>
          <div>
            <span class="value has-text-blue">{{ownershipPercentage}}</span>
            <span class="sign sub has-text-weight-semibold has-text-blue">%</span>
          </div>
        </div>
      </div>
      <div class="is-flex is-justify-content-space-between">
        <div class="metric-block has-background-white has-text-centered">
          <div class="metric-title has-text-weight-semibold">Distributions Earned</div>
          <div class="has-padding">
            <span class="sign sup has-text-weight-semibold has-text-blue">$</span>
            <span class="value has-text-blue">{{totalDistributionReceived | truncate | formatter}}</span>
          </div>
        </div>
        <div class="metric-block has-background-white is-relative">
          <div class="metrics-swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide has-text-centered">
                <div class="metric-title has-text-weight-semibold">Estimated ROI</div>
                <div>
                  <span class="value has-text-blue">{{estimatedRoi}}</span>
                  <span class="sign sub has-text-weight-semibold has-text-blue">%</span>
                </div>
              </div>
              <div class="swiper-slide has-text-centered">
                <div class="metric-title has-text-weight-semibold">Estimated IRR</div>
                <div>
                  <span class="value has-text-blue">{{estimatedIrr ? estimatedIrr : 'n/a'}}</span>
                  <span class="sign sub has-text-weight-semibold has-text-blue" v-if="estimatedIrr">%</span>
                </div>
              </div>
            </div>
          </div>
          <img src="@assets/icons/switch.svg" alt="switch-icon" class="switch-icon is-clickable" @click="switchMetrics">
        </div>
      </div>
    </div>
    <div v-if="!isCertificateSharing" class="graph-wrap">
      <FutureValueGraph
        :estimatedRoi="estimatedRoi"
        :totalInvestedAmount="totalInvestedAmount"
        :investmentTerm="investmentTerm"
      ></FutureValueGraph>
    </div>
    <div class="share-certificate">
      <div class="section-title has-text-weight-semibold">Share certificate</div>
      <InvestmentShareCertificate
          v-if="certificateDetails != null"
        :certificate-title="certificateDetails.certificateTitle"
        :member-full-name="certificateDetails.investorName"
        :security-type="certificateDetails.securityType"
        :security-count="certificateDetails.securityCount"
        :date="certificateDetails.date"
        :date-label="certificateDetails.dateLabel"
      />
    </div>
    <div class="signup has-text-centered">
      <img src="@assets/images/portfolio/house.png" alt="house" class="house">
      <div class="section-title has-text-weight-semibold">Want to invest in real estate?</div>
      <div class="section-content">Use my referral link to sign up with addy and get access to these opportunities too!</div>
      <div class="has-text-centered">
        <button class="button is-primary is-rounded is-medium-height has-text-weight-semibold" @click="toSignupPage">Sign up</button>
      </div>
      <a href="https://www.addyinvest.com/terms-of-use/" target="_blank" class="terms">Terms of Use</a>
      <div class="version">v1.01</div>
    </div>
  </div>
</template>
<script>
import Swiper from 'swiper'
import 'swiper/swiper-bundle.css'
import { formatter, truncate } from '@utils/common-methods/numberFormatter'
import { getPublicPortfolioDetails } from '@api/portfolio'
import InvestmentShareCertificate from './InvestmentShareCertificate'
import FutureValueGraph from './FutureValueGraph.vue'
import { mapState } from 'vuex'
import desktopUiFixes from '@utils/mixins/desktop-no-common-parts-ui-fixes.js'

export default {
  mixins: [desktopUiFixes],
  data() {
    return {
      isCertificateSharing: true,
      memberName: '',
      propertyNickname: '',
      addyHandle: '',
      propertyName: '',
      miniProp: '',
      propertyImages: [],
      totalInvestedAmount: 0,
      propertySoldOut: false,
      saleStatus: '',
      ownershipPercentage: 0,
      totalDistributionReceived: 0,
      estimatedRoi: 0,
      estimatedIrr: 0,
      investmentTerm: 0,
      imageSwiper: null,
      metricSwiper: null,
      certificateDetails: null,
    }
  },
  computed: {
    ...mapState('mixpanel', ['mixpanel']),
    realisticProfit() {
      return this.estimatedRoi / 100 * this.totalInvestedAmount
    },
  },
  components: {
    InvestmentShareCertificate,
    FutureValueGraph,
  },
  created() {
    this.toggleOffComponentsForCertificate()
    this.fetchData()
  },
  mounted() {
    this.mixpanel.track('y9btarz8')
  },
  filters: {
    formatter(value) {
      return formatter(value)
    },
    truncate(num) {
      return truncate(num, 2)
    },
  },
  methods: {
    toggleOffComponentsForCertificate() {
      this.isCertificateSharing = this.$route.query.certificate !== undefined
    },
    fetchData() {
      getPublicPortfolioDetails(decodeURIComponent(this.$route.query.jwt)).then((data) => {
        if (!data.success) return
        const {
          memberName,
          propertyNickname,
          addyHandle,
          propertyName,
          miniProp,
          primaryImage,
          secondaryImages,
          totalInvestedAmount,
          propertySoldOut,
          saleStatus,
          ownershipPercentage,
          totalDistributionReceived,
          estimatedRoi,
          estimatedIrr,
          term,
          certificateData,
        } = data.data
        this.memberName = memberName
        this.propertyNickname = propertyNickname
        this.addyHandle = addyHandle
        this.propertyName = propertyName
        this.miniProp = miniProp
        this.totalInvestedAmount = totalInvestedAmount
        this.propertySoldOut = propertySoldOut
        this.saleStatus = saleStatus
        this.ownershipPercentage = ownershipPercentage
        this.totalDistributionReceived = totalDistributionReceived
        this.estimatedRoi = estimatedRoi
        this.estimatedIrr = estimatedIrr
        this.investmentTerm = term
        this.propertyImages.push(primaryImage)
        this.propertyImages = [...this.propertyImages, ...secondaryImages]
        this.certificateDetails = certificateData
        this.$nextTick(() => {
          this.initializeImages()
          this.initializeMetrics()
        })
      })
    },
    initializeImages() {
      /* eslint-disable no-new */
      this.imageSwiper = new Swiper('.property-images-swiper-container', {
        initialSlide: 0,
        loop: true,
      })
    },
    initializeMetrics() {
      this.metricSwiper = new Swiper('.metrics-swiper-container', {
        initialSlide: 0,
      })
    },
    slidePrev() {
      this.imageSwiper.slidePrev()
    },
    slideNext() {
      this.imageSwiper.slideNext()
    },
    switchMetrics() {
      if (this.metricSwiper.activeIndex) this.metricSwiper.slidePrev()
      else this.metricSwiper.slideNext()
    },
    toSignupPage() {
      this.$router.push(`/signup/create?addyHandle=${encodeURIComponent(this.addyHandle)}`)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

@media screen and (min-width: 769px) {
  .public-portfolio-wrap {
    max-width: 375px;
    margin: 0 auto;
  }
}
.public-portfolio-wrap {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: #FDFDFD;
  .page-header {
    background-color: #F0EEF8;
    width: 100%;
    .avatar {
      width: 50px;
      margin-left: 25px;
      margin-right: 9px;
    }
    .has-padding {
      padding: 15px 0;
      padding-right: 16px;
      .header-title {
        width: 263px;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 4px;
      }
      .handle {
        font-size: 16px;
      }
    }
  }
  .miniprop {
    margin-top: 24px;
    .property-nickName {
      font-size: 24px;
    }
    .property-address {
      margin-top: 4px;
      font-size: 16px;
      line-height: 19px;
      &.noMiniPropImage {
        padding-bottom: 42px;
      }
    }
    .miniprop-wrap {
      width: 260px;
      height: 120px;
      margin: 20px auto 0;
    }
  }
  .property-images {
    width: 100%;
    overflow: hidden;
    margin-top: 33px;
    .has-primary-only {
      height: 180px;
      padding: 0 25px;
      .primary-img {
        border-radius: 6px;
        height: 100%;
      }
    }
    .swiper-slide {
      height: 180px;
      padding: 0 25px;
      .image-block {
        width: 100%;
        height: 100%;
        border-radius: 6px;
      }
    }
    .arrows {
      img {
        padding: 17px 4px;
        &.arrow-left {
          margin-right: 50px;
        }
      }
    }
  }
  .metrics {
    padding: 34px 25px 42px;
    > .has-margin {
      margin-bottom: 12px;
    }
    .metric-block {
      width: calc(50% - 6px);
      height: 97px;
      border-radius: 12px;
      box-shadow: 0px 4px 50px rgba(215, 145, 51, 0.11);
      overflow: hidden;
      &.full-width {
        width: 100%;
      }
      .placeholder {
        font-size: 30px;
        padding-top: 1px;
      }
      .switch-icon {
        position: absolute;
        right: 6px;
        top: 4px;
        z-index: 2;
        padding: 4px;
      }
      .metrics-swiper-container {
        height: 100%;
      }
      .metric-title {
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 10px;
      }
      .has-padding {
        padding-top: 1px;
      }
      .sign {
        font-size: 20px;
        &.sup {
          vertical-align: top;
          line-height: 26px;
          margin-right: 2px;
        }
        &.sub {
          vertical-align: bottom;
          line-height: 23px;
          margin-left: 2px;
        }
      }
      .value {
        font-size: 32px;
      }
    }
  }
  .graph-wrap {
    padding-bottom: 44px;
  }
  .share-certificate {
    padding-bottom: 30px;
    .section-title {
      font-size: 24px;
      padding-left: 25px;
      margin-bottom: 12px;
    }
    .share-certificate-image {
      width: 354px;
      border-radius: 6px;
    }
  }
  .signup {
    padding: 0 33px 80px;
    .house {
      width: 105px;
    }
    .section-title {
      font-size: 24px;
      margin-bottom: 16px;
    }
    .section-content {
      font-size: 16px;
      line-height: 19px;
    }
    .button {
      width: 277px;
      margin-top: 32px;
      margin-bottom: 36px;
    }
    .terms {
      font-size: 16px;
      line-height: 26px;
    }
    .version {
      font-size: 14px;
      line-height: 19px;
      color: #939598;
    }
  }
}

@media only screen and (min-width: $min-viewport-width) {
  .public-portfolio-wrap {
    max-width: $min-viewport-width;
    .page-header {
      margin-top: -20px;
    }
  }
}
</style>
